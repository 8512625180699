exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-commandes-tsx": () => import("./../../../src/pages/commandes.tsx" /* webpackChunkName: "component---src-pages-commandes-tsx" */),
  "component---src-pages-en-product-produit-pathname-tsx": () => import("./../../../src/pages/en/product/{Produit.pathname}.tsx" /* webpackChunkName: "component---src-pages-en-product-produit-pathname-tsx" */),
  "component---src-pages-en-search-[query]-[page]-tsx": () => import("./../../../src/pages/en/search/[query]/[page].tsx" /* webpackChunkName: "component---src-pages-en-search-[query]-[page]-tsx" */),
  "component---src-pages-en-search-[query]-tsx": () => import("./../../../src/pages/en/search/[query].tsx" /* webpackChunkName: "component---src-pages-en-search-[query]-tsx" */),
  "component---src-pages-fr-a-propos-tsx": () => import("./../../../src/pages/fr/a-propos.tsx" /* webpackChunkName: "component---src-pages-fr-a-propos-tsx" */),
  "component---src-pages-fr-caracteristique-tsx": () => import("./../../../src/pages/fr/caracteristique.tsx" /* webpackChunkName: "component---src-pages-fr-caracteristique-tsx" */),
  "component---src-pages-fr-checkout-tsx": () => import("./../../../src/pages/fr/checkout.tsx" /* webpackChunkName: "component---src-pages-fr-checkout-tsx" */),
  "component---src-pages-fr-commande-[id]-tsx": () => import("./../../../src/pages/fr/commande/[id].tsx" /* webpackChunkName: "component---src-pages-fr-commande-[id]-tsx" */),
  "component---src-pages-fr-contact-tsx": () => import("./../../../src/pages/fr/contact.tsx" /* webpackChunkName: "component---src-pages-fr-contact-tsx" */),
  "component---src-pages-fr-devis-tsx": () => import("./../../../src/pages/fr/devis.tsx" /* webpackChunkName: "component---src-pages-fr-devis-tsx" */),
  "component---src-pages-fr-newsletter-tsx": () => import("./../../../src/pages/fr/newsletter.tsx" /* webpackChunkName: "component---src-pages-fr-newsletter-tsx" */),
  "component---src-pages-fr-panier-tsx": () => import("./../../../src/pages/fr/panier.tsx" /* webpackChunkName: "component---src-pages-fr-panier-tsx" */),
  "component---src-pages-fr-produit-produit-pathname-tsx": () => import("./../../../src/pages/fr/produit/{Produit.pathname}.tsx" /* webpackChunkName: "component---src-pages-fr-produit-produit-pathname-tsx" */),
  "component---src-pages-fr-recherche-[query]-[page]-tsx": () => import("./../../../src/pages/fr/recherche/[query]/[page].tsx" /* webpackChunkName: "component---src-pages-fr-recherche-[query]-[page]-tsx" */),
  "component---src-pages-fr-recherche-[query]-tsx": () => import("./../../../src/pages/fr/recherche/[query].tsx" /* webpackChunkName: "component---src-pages-fr-recherche-[query]-tsx" */),
  "component---src-pages-fr-stripe-success-tsx": () => import("./../../../src/pages/fr/stripe/success.tsx" /* webpackChunkName: "component---src-pages-fr-stripe-success-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-maintenance-tsx": () => import("./../../../src/pages/maintenance.tsx" /* webpackChunkName: "component---src-pages-maintenance-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-templates-articles-list-tsx": () => import("./../../../src/templates/articles-list.tsx" /* webpackChunkName: "component---src-templates-articles-list-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-produits-tsx": () => import("./../../../src/templates/produits.tsx" /* webpackChunkName: "component---src-templates-produits-tsx" */)
}

