module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#faf8f4","display":"minimal-ui","icon":"src/images/favicon.svg","name":"Barrois Antiques","short_name":"Barrois","start_url":"/","theme_color":"#513d2b","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8063e4030e4f4f17d4c926e47300db90"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
